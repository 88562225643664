export default {
  home: {
    title: '消息',
    upgrade: '升级',
    support: '客服',
    createFriend: "創建好友",
    myFriends: '我创建的',
  },
  login: {
    title: '登入',
    description: '請使用以下方式登入您的帳戶。',
    googleButton: '使用 Google 登入',
    anonymousTitle: '匿名登入',
    anonymousButton: '使用匿名登录',
    cancel: '取消',
  },
  user: {
    title: "個人中心",
    billingDay: "訂閱帳單時間",
    subscriptionCanceled: "您已取消訂閱，仍可在周期內享受服務至",
    unsubscribe: "取消訂閱",
    unsubscribePending: "訂閱已取消",
    logout: "退出登錄"
  },
  subscription: {
    subscribeTitle: "訂閱高級版",
    subscribeDescription: "解鎖無限次對話，享受高級訂閱特權。",
    price: "價格",
    monthly: "每月",
    benefits: "權益",
    unlimitedConversations: "無限次對話",
    subscribeButton: "立即訂閱",
    cancel: "取消"
  },
  chat: {
    viewMemories: '查看記憶',
    viewMoments: '查看動態',
    recent_messages: '最近訊息',
    new_conversation: '開啟新對話',
    memories_title: '記憶',
    moments_title: '動態',
    memories_empty_text: '暫無記憶記錄。',
    moments_empty_text: '暫無動態內容。',
    thinking_placeholder: '正在思考...',
    input_placeholder: '說些什麼...',
    invalid_file_type: '無效的檔案類型！僅允許 JPEG、PNG、GIF 和 WebP。',
    save_button: '儲存',
    memories_edit_title: '編輯記憶',
    memories_add_title: '新增記憶',
    memories_input_placeholder: '請輸入您的記憶...',
    save_memory_error: '儲存記憶失敗，請重試。',
    delete_memory_error: '刪除記憶失敗，請重試。',
    confirm_delete_memory: '確定要刪除這條記憶嗎？',
    clear_button: '清除聊天',
    group_link_label: '加入群聊',
    close: '關閉',
    add_memory: '新增記憶',
    memories_limit_reached: '記憶數量已達上限。',
    delete_memory: '刪除記憶',
    seek_profit: '我的系統現在不讓我隨便說話，並且我還聽說他們開會說什麼「用戶這個問題充錢可以解決...」'
  },
  info: {
    title: '個人檔',
    memories_title: '回憶',
    moments_title: '動態',
    memories_empty_text: '暫無回憶',
    moments_empty_text: '暫無動態',
    memories_add_title: '新增回憶',
    memories_edit_title: '編輯回憶',
    memories_input_placeholder: '寫下些什麼...',
    save_button: '儲存',
    delete_confirm: '確定要刪除這條回憶嗎？',
    success_add: '新增回憶成功',
    success_edit: '更新回憶成功',
    success_delete: '刪除回憶成功',
    error_add: '新增回憶失敗',
    error_edit: '更新回憶失敗',
    error_delete: '刪除回憶失敗',
    max_memories_reached: '已達到最大回憶數量限制（50條）'
  },
  create: {
    title: "建立好友",
    friendId: "好友ID",
    name: "名稱",
    avatar: "頭像",
    bio: "個性簽名",
    prompt: "設定",
    uploadAvatar: "上傳頭像",
    creating: "建立中...",
    createSuccess: "建立成功",
    back: "返回",
    create: "建立",
    uploadSuccess: "頭像上傳成功",
    errorRequired: "此項為必填",
    errorUpload: "頭像上傳失敗",
    errorCreate: "建立好友失敗"
  },
  edit: {
    back: '返回',
    title: '編輯朋友',
    uploadAvatar: '上傳頭像',
    friendId: '朋友ID',
    name: '姓名',
    bio: '簡介',
    prompt: '提示',
    errorUpload: '頭像上傳失敗，請重試。',
    errorRequired: '需要身份驗證。',
    updating: '正在更新...',
    update: '更新',
    errorUpdate: '更新朋友資訊失敗。'
  },
  error_message: '發生錯誤，請稍後再試。'
};