<template>
    <div class="min-h-screen bg-hearts bg-gradient-to-b from-pink-50/80 to-white/90">
        <!-- 顶部导航栏 -->
        <header class="fixed top-0 left-0 right-0 bg-opacity-75 z-50 backdrop-blur-md">
            <div class="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
                <div class="flex items-center justify-between h-16">
                    <button @click="goBack"
                        class="flex items-center text-gray-600 hover:text-gray-900 transition-colors">
                        <ChevronLeftIcon class="w-5 h-5 mr-1" />
                        <span>{{ t('create.back') }}</span>
                    </button>

                    <h1 class="text-xl font-semibold text-gray-900 tracking-tight">
                        {{ t('create.title') }}
                    </h1>

                    <div class="w-5"></div>
                </div>
            </div>
        </header>

        <!-- 主内容区 -->
        <main class="pt-20 pb-24 h-screen overflow-y-auto custom-scrollbar">
            <div class="max-w-2xl mx-auto px-4">
                <form @submit.prevent="submitForm" class="space-y-6">
                    <!-- 头像上传区域 -->
                    <div class="flex flex-col items-center space-y-4">
                        <div class="relative group">
                            <div
                                class="w-24 h-24 rounded-full overflow-hidden bg-gray-100 flex items-center justify-center">
                                <img v-if="avatarUrl" :src="avatarUrl" alt="Avatar"
                                    class="w-full h-full object-cover" />
                                <UserIcon v-else class="w-12 h-12 text-gray-400" />
                            </div>
                            <label
                                class="absolute inset-0 flex items-center justify-center bg-black/40 text-white opacity-0 group-hover:opacity-100 cursor-pointer rounded-full transition-opacity">
                                <CameraIcon class="w-6 h-6" />
                                <input type="file" @change="handleFileChange" accept="image/*" class="hidden" />
                            </label>
                        </div>
                        <p class="text-sm text-gray-500">{{ t('create.uploadAvatar') }}</p>
                    </div>

                    <!-- 表单字段 -->
                    <div class="bg-white rounded-xl shadow-sm p-6 space-y-5">
                        <!-- Friend ID -->
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">{{ t('create.friendId')
                                }}</label>
                            <input v-model="form.friend_id" type="text" required
                                class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors" />
                        </div>

                        <!-- Name -->
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">{{ t('create.name') }}</label>
                            <input v-model="form.name" type="text" required
                                class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors" />
                        </div>

                        <!-- Bio -->
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">{{ t('create.bio') }}</label>
                            <input v-model="form.bio" rows="3"
                                class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors resize-none" />
                        </div>

                        <!-- Prompt -->
                        <div>
                            <label class="block text-sm font-medium text-gray-700 mb-1">{{ t('create.prompt') }}</label>
                            <textarea v-model="form.prompt" rows="5"
                                class="w-full px-4 py-2 rounded-lg border border-gray-200 focus:border-blue-500 focus:ring-1 focus:ring-blue-500 transition-colors resize-none custom-scrollbar"></textarea>
                        </div>

                        <button type="submit" :disabled="isSubmitting"
                            class="w-full px-6 py-2.5 bg-gradient-to-r from-gray-800 to-black text-white font-medium rounded-lg shadow-lg hover:from-gray-900 hover:to-gray-800 focus:ring-4 focus:ring-gray-500/50 transition-all disabled:opacity-50">
                            <div class="flex items-center justify-center">
                                <SpinnerIcon v-if="isSubmitting" class="animate-spin -ml-1 mr-2 h-4 w-4" />
                                {{ isSubmitting ? t('create.creating') : t('create.create') }}
                            </div>
                        </button>
                    </div>

                    <!-- Error Message -->
                    <div v-if="errorMessage" class="rounded-lg bg-red-50 p-4">
                        <div class="flex">
                            <ExclamationCircleIcon class="h-5 w-5 text-red-400" />
                            <p class="ml-3 text-sm text-red-500">{{ errorMessage }}</p>
                        </div>
                    </div>
                    
                </form>
            </div>
        </main>
    </div>
</template>

<script setup>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import {
    ChevronLeftIcon,
    UserIcon,
    CameraIcon,
    ExclamationCircleIcon
} from '@heroicons/vue/24/outline'
import { region } from '../hooks/region'
import { getCookie } from '../utils'

const { t } = region()
const router = useRouter()

// Form state
const form = ref({
    friend_id: '',
    name: '',
    avatar_url: '',
    bio: '',
    prompt: ''
})

// UI state
const avatarUrl = ref('')
const errorMessage = ref('')
const isSubmitting = ref(false)
const selectedFile = ref(null)

// File handling
const handleFileChange = async (event) => {
    const file = event.target.files[0]
    if (!file) return

    if (file.size > 5 * 1024 * 1024) {
        errorMessage.value = t('create.errorUpload')
        return
    }

    selectedFile.value = file
    await uploadAvatar()
}

// Upload avatar
const uploadAvatar = async () => {
    if (!selectedFile.value) return

    const token = getCookie('chatnext_token')
    if (!token) {
        errorMessage.value = t('create.errorRequired')
        router.push({ name: 'Home' })
        return
    }

    const formData = new FormData()
    formData.append('avatar', selectedFile.value)

    try {
        const response = await fetch('/api/friend/avatar', {
            method: 'POST',
            headers: { 'Authorization': `Bearer ${token}` },
            body: formData
        })

        const data = await response.json()

        if (response.ok) {
            avatarUrl.value = data.avatar_url
            form.value.avatar_url = data.avatar_url
        } else {
            throw new Error(data.error || t('create.errorUpload'))
        }
    } catch (error) {
        console.error('Avatar upload error:', error)
        errorMessage.value = error.message
    }
}

// Form submission
const submitForm = async () => {
    if (isSubmitting.value) return
    errorMessage.value = ''
    isSubmitting.value = true

    try {
        const token = getCookie('chatnext_token')
        if (!token) {
            throw new Error(t('create.errorRequired'))
        }

        const response = await fetch('/api/friend/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            body: JSON.stringify(form.value)
        })

        const data = await response.json()

        if (response.ok) {
            router.push({
                name: 'Home',
                query: { created: 'true' }
            })
        } else {
            throw new Error(data.error || t('create.errorCreate'))
        }
    } catch (error) {
        console.error('Create friend error:', error)
        errorMessage.value = error.message
    } finally {
        isSubmitting.value = false
    }
}

// Navigation
const goBack = () => router.back()
</script>