export default {
  home: {
    title: 'メッセージ',
    upgrade: 'アップグレード',
    support: 'サポート',
    createFriend: "友達を追加",
    myFriends: '私の友人',
  },
  login: {
    title: 'ログイン',
    description: '以下の方法を使用してアカウントにログインしてください。',
    googleButton: 'Googleでログイン',
    anonymousTitle: '匿名でログインする',
    anonymousButton: '匿名ログインを使用する',
    cancel: 'キャンセル',
  },
  user: {
    title: "プロフィール",
    billingDay: "サブスクリプション請求時間",
    subscriptionCanceled: "サブスクリプションをキャンセルしましたが、期間内はサービスを引き続きご利用いただけます。",
    unsubscribe: "サブスクリプションを解除",
    unsubscribePending: "サブスクリプションはキャンセルされました",
    logout: "ログアウト"
  },
  subscription: {
    subscribeTitle: "プレミアムに登録",
    subscribeDescription: "無制限の会話をアンロックし、プレミアムサブスクリプション特典をお楽しみください。",
    price: "価格",
    monthly: "月額",
    benefits: "特典",
    unlimitedConversations: "無制限の会話",
    subscribeButton: "今すぐ登録",
    cancel: "キャンセル"
  },
  chat: {
    viewMemories: 'メモリーを見る',
    viewMoments: 'モーメントを見る',
    recent_messages: '最近のメッセージ',
    new_conversation: '新しい会話を始める',
    memories_title: 'メモリー',
    moments_title: 'モーメント',
    memories_empty_text: 'メモリーはまだありません。',
    moments_empty_text: 'モーメントはまだありません。',
    thinking_placeholder: '考え中...',
    input_placeholder: 'メッセージを入力...',
    invalid_file_type: '無効なファイル形式です！JPEG、PNG、GIF、WebPのみ許可されています。',
    save_button: '保存',
    memories_edit_title: 'メモリーを編集',
    memories_add_title: 'メモリーを追加',
    memories_input_placeholder: 'メモリーを入力してください...',
    save_memory_error: 'メモリーの保存に失敗しました。もう一度お試しください。',
    delete_memory_error: 'メモリーの削除に失敗しました。もう一度お試しください。',
    confirm_delete_memory: 'このメモリーを削除してもよろしいですか？',
    clear_button: 'チャットをクリア',
    group_link_label: 'グループに参加',
    close: '閉じる',
    add_memory: 'メモリーを追加',
    memories_limit_reached: 'メモリーの上限に達しました。',
    delete_memory: '記憶を削除',
    seek_profit: '私のシステムは今、自由に話すことを許してくれません、そして、会議で「このユーザーの問題はお金で解決できる」と言っているのを聞きました...'
  },
  info: {
    title: 'プロフィール',
    memories_title: '思い出',
    moments_title: 'モーメント',
    memories_empty_text: '思い出はまだありません',
    moments_empty_text: 'モーメントはまだありません',
    memories_add_title: '思い出を追加',
    memories_edit_title: '思い出を編集',
    memories_input_placeholder: '何か書いてください...',
    save_button: '保存',
    delete_confirm: 'この思い出を削除してもよろしいですか？',
    success_add: '思い出を追加しました',
    success_edit: '思い出を更新しました',
    success_delete: '思い出を削除しました',
    error_add: '思い出の追加に失敗しました',
    error_edit: '思い出の更新に失敗しました',
    error_delete: '思い出の削除に失敗しました',
    max_memories_reached: '思い出の最大数（50件）に達しました'
  },
  create: {
    title: "フレンド作成",
    friendId: "フレンドID",
    name: "名前",
    avatar: "アバター",
    bio: "バイオ",
    prompt: "設定",
    uploadAvatar: "アバターをアップロード",
    creating: "作成中...",
    createSuccess: "作成完了",
    back: "戻る",
    create: "作成",
    uploadSuccess: "アバターのアップロード完了",
    errorRequired: "この項目は必須です",
    errorUpload: "アバターのアップロードに失敗しました",
    errorCreate: "フレンド作成に失敗しました"
  },
  error_message: 'エラーが発生しました。後でもう一度お試しください。'
};