export default {
    home: {
        title: 'Messages',
        upgrade: 'Upgrade',
        support: 'Support',
        createFriend: "Add friends",
        myFriends: 'my friends',
    },
    login: {
        title: 'Login',
        description: 'Please use the following methods to log into your account.',
        googleButton: 'Login with Google',
        anonymousTitle: 'Login Anonymously',
        anonymousButton: 'Use anonymous login',
        cancel: 'Cancel',
    },
    user: {
        title: "Profile",
        billingDay: "Subscription Billing Time",
        subscriptionCanceled: "You have canceled your subscription and can continue to enjoy the service until",
        unsubscribe: "Unsubscribe",
        unsubscribePending: "Subscription Canceled",
        logout: "Logout"
    },
    subscription: {
        subscribeTitle: 'Subscribe to Premium',
        subscribeDescription: 'Unlock unlimited conversations with premium subscription.',
        price: 'Price',
        monthly: "monthly",
        // priceValue: '$4.99',
        benefits: 'Benefits',
        unlimitedConversations: 'Unlimited conversations',
        subscribeButton: 'Subscribe Now',
        cancel: 'Cancel',
    },
    chat: {
        viewMemories: 'View Memories',
        viewMoments: 'View Moments',
        recent_messages: 'Recent Messages',
        new_conversation: 'Start New Conversation',
        memories_title: 'Memories',
        moments_title: 'Moments',
        memories_empty_text: 'No memories found.',
        moments_empty_text: 'No moments available.',
        thinking_placeholder: 'thinking...',
        input_placeholder: 'Say something...',
        invalid_file_type: 'Invalid file type! Only JPEG, PNG, GIF, and WebP are allowed.',
        save_button: 'Save',
        memories_edit_title: 'Edit Memory',
        memories_add_title: 'Add Memory',
        memories_input_placeholder: 'Enter your memory...',
        save_memory_error: 'Failed to save memory, please try again.',
        delete_memory_error: 'Failed to delete memory, please try again.',
        confirm_delete_memory: 'Are you sure you want to delete this memory?',
        clear_button: 'Clear Chat',
        group_link_label: 'Join Group Chat',
        close: 'Close',
        add_memory: 'Add Memory',
        memories_limit_reached: 'Memory limit reached.',
        delete_memory: 'Delete Memory',
        seek_profit: `My system doesn't allow me to speak freely now, and I also heard them say in a meeting something like "This user issue can be solved with money..."`
    },
    info: {
        title: 'Conversation info',
        memories_title: 'Memories',
        moments_title: 'Moments',
        memories_empty_text: 'No memory yet',
        moments_empty_text: 'No moment yet',
        memories_add_title: 'Add Memory',
        memories_edit_title: 'Edit Memory',
        memories_input_placeholder: 'Write something...',
        save_button: 'Save',
        delete_confirm: 'Are you sure you want to delete this memory?',
        success_add: 'Memory added successfully',
        success_edit: 'Memory updated successfully',
        success_delete: 'Memory deleted successfully',
        error_add: 'Failed to add memory',
        error_edit: 'Failed to update memory',
        error_delete: 'Failed to delete memory',
        max_memories_reached: "You've reached the maximum number of memories (50)"
    },
    // 英文配置
    create: {
        title: "Create Friend",
        friendId: "Friend ID",
        name: "Name",
        avatar: "Avatar",
        bio: "Bio",
        prompt: "Prompt",
        uploadAvatar: "Upload Avatar",
        creating: "Creating...",
        createSuccess: "Friend created successfully",
        back: "Back",
        create: "Create",
        uploadSuccess: "Avatar uploaded successfully",
        errorRequired: "This field is required",
        errorUpload: "Failed to upload avatar",
        errorCreate: "Failed to create friend"
    },

    edit: {
        back: 'Back',
        title: 'Edit Friend',
        uploadAvatar: 'Upload Avatar',
        friendId: 'Friend ID',
        name: 'Name',
        bio: 'Bio',
        prompt: 'Prompt',
        errorUpload: 'Avatar upload failed. Please try again.',
        errorRequired: 'Authentication required.',
        updating: 'Updating...',
        update: 'Update',
        errorUpdate: 'Failed to update friend.'
    },

    error_message: 'An error occurred, please try again later.'
};
